<template>
  <main>
    <section>
      <b-container class="ombre">
        <b-col id="page-header">
          <b-btn
            :href="`${$t('config.wiki.base')}${$t('lang') === 'fr'
              ? 'fr' : 'en'}/news/Article title`"
            size="sm"
            variant="outline-secondary float-right m-2 btn-wiki-edit"
          >
            <i class="fas fa-plus"></i>
            <span v-html="$t('news.add')"></span>
          </b-btn>
          <h1 v-html="$t('news.title')"></h1>
        </b-col>

        <hr class="trait" />

        <b-row
          align-h="center"
        >
          <b-col lg="8">
            <p
              v-html="$t('news.subtitle')"
            ></p>
            <div class="px-5 py-4 nl-form">
              <form
                action="https://listmonk.framasoft.org/subscription/form"
                method="post"
              >
                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <!-- Only decorative-->
                    <b-button
                      aria-hidden="true"
                      class="disabled"
                    >
                      <i class="far fa-envelope fa-fw fa-white"></i>
                    </b-button>
                    <label
                      class="sr-only"
                      for="nl-email"
                      v-html="$t('newsletter.your-email')"
                    ></label>
                  </b-input-group-prepend>
                  <b-form-input
                    id="nl-email"
                    v-model="email"
                    name="email"
                    required
                    type="email"
                    size="40"
                    :title="$t('newsletter.type-your-email')"
                    :placeholder="$t('newsletter.your-email')"
                  />
                </b-input-group>
                <div class="text-right">
                  <!-- <Unsubscribe :email="email" /> -->
                  <b-button
                    variant="secondary"
                    name="subscribe"
                    type="submit"
                    value="subscribe"
                  >
                    <i class="fas fa-bell fa-white"></i>
                    <span v-html="$t('newsletter.subscribe')"></span>
                  </b-button>
                </div>
                <input
                  name="nonce"
                  type="hidden"
                />
                <div style="display: none;">
                  <input
                    id="3fd37"
                    checked
                    name="l"
                    type="checkbox"
                    value="3fd37325-9905-4ff1-9a07-7441eac6147d"
                  />
                </div>
              </form>
            </div>
          </b-col>
        </b-row>
        <b-row
          class="mt-4 mb-4 mx-0"
          tag="hr"
        />

        <b-row>
          <b-col
            v-if="$te('news.posts')"
            lg="8"
          >
            <article
              v-for="key in posts"
              :id="key"
              :key="key"
            >
              <img
                v-if="$te(`news.posts.${key}.img`) && $t(`news.posts.${key}.img`) !== ''"
                alt=""
                class="img-fluid"
                :src="$t(`news.posts.${key}.img`)"
              />
              <b-btn
                :href="`${$t('config.wiki.base')}${
                  encodeURIComponent($t(`news.posts.${key}.edit`))}/edit`"
                size="sm"
                variant="outline-secondary badge float-right mt-2 btn-wiki-edit"
              >
                <i class="fas fa-pen"></i>
                <span v-html="$t('news.edit')"></span>
              </b-btn>
              <div class="content-wrapper">
                <h1>
                  <a
                    :href="`#${key}`"
                    v-html="$t(`news.posts.${key}.title`)"
                  ></a>
                </h1>
                <p class="text-muted small">
                  <i class="fas fa-calendar"></i>
                  <time
                    :datetime="`20${key}`"
                    v-text="`20${key}`"
                  ></time>
                </p>
                <div
                  class="content"
                  v-html="$t(`news.posts.${key}.text`)"
                ></div>
              </div>
            </article>
          </b-col>
          <!-- Right column -->
          <b-col lg="4">
            <div class="sticky-top">
              <div class="bloc">
                <nav v-if="$te('news.posts')">
                  <h3>
                    <span v-html="$t(`news.latest-posts`)"></span>
                    <a
                      :href="`${$t('baseurl')}${
                        $route.path.replace(/^\//, '').replace(/\/?$/, '/')}feed.xml`"
                      :title="this.$t('news.rss')"
                    >
                      <i class="fas fa-square-rss fa-xs fc-o6"></i>
                      <span
                        class="sr-only"
                        v-html="this.$t('news.rss')"
                      ></span>
                    </a>
                  </h3>
                  <ul>
                    <li
                      v-for="key in Object.keys($t('news.posts')).sort().slice().reverse().slice(0, 5)"
                      :id="key"
                      :key="key"
                    >
                      <a
                        :href="`#${key}`"
                        v-html="$t(`news.posts.${key}.title`)"
                      ></a>
                      <time
                        class="text-muted small"
                        :datetime="`20${key}`"
                        v-text="`20${key}`"
                      ></time>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
export default {
  metaInfo() {
    return {
      link: [
        {
          rel: 'alternate',
          type: 'application/rss+xml',
          title: this.$t('meta.title'),
          href: `${this.$t('baseurl')}${
            this.$route.path.replace(/^\//, '').replace(/\/?$/, '/')}feed.xml`,
        },
      ],
    };
  },

  data() {
    return {
      email: '',
    };
  },

  computed: {
    posts() {
      return Object.keys(this.$t('news.posts')).slice().sort().reverse();
    },
  },

  created() {
    /* Edition mode */
    const q = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if (q.edit !== undefined) {
      document.body.classList.add('wiki-edit-enabled');
    } else {
      document.body.classList.remove('wiki-edit-enabled');
    }
  },
};
</script>

<style lang="scss">
#news {
  article {
    margin-bottom: 2.5rem;

    p:last-of-type > img,
    img.square {
      max-width: 420px;
      margin: 1.5rem auto;
      display:block;
    }

    h2::after { content: none; }
  }

  .content-wrapper {
    padding: 2rem 1.5rem;

    img {
      margin: 1rem auto;
    }
  }

  .content {
    margin-top: 1.5rem;

    img {
      max-width: 100%;
    }
  }

  .btn-wiki-edit {
    visibility: hidden;
  }

  &.wiki-edit-enabled {
    .btn-wiki-edit {
      visibility: visible;
    }
  }

  .nl-form {
    background: var(--f-g1);
  }
}
</style>
