<template>
  <section
    id="news"
    ref="news"
  >
    <b-container class="px-md-0">
      <b-row
        id="h-blog"
        class="mx-n3"
      >
        <div class="w-100 mx-3">
          <h2 v-html="$t('hos.news.title', $t('hos.data'))"></h2>
        </div>
        <b-col
          v-for="(article, index) in articles[$t('lang').replace(/^(?!fr$).*$/, 'en')]"
          :key="index"
          :class="`mb-4 ${index > 1 ? 'd-none d-lg-block' : ''}`"
          md="6"
          lg="4"
        >
          <div class="p-4">
            <a :href="article.url">
              <img
                alt=""
                class="mb-3"
                :src="article.img"
                style="height: 200px; width: 100%; object-fit: cover"
              />
            </a>
            <p class="small mb-1">
              <i class="fas fa-calendar"></i>
              <time :datetime="article.date">
                {{ article.date.split('T')[0] }}
              </time>
            </p>
            <div>
              <a :href="article.url">
                <span
                  v-html="article.title"
                ></span>
              </a>
              <p
                class="desc"
                v-html="article.desc"
              ></p>
              <a :href="article.url">
                <p class="mt-2 mb-0 text-right">
                  <span class="btn btn-outline-warning">
                    <span v-html="$t('hos.news.read')"></span>
                    <i class="fas fa-chevron-right"></i>
                  </span>
                </p>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      articles: {
        fr: [],
        en: [],
      },
    };
  },

  created() {
    if (/^fr/.test(this.$t('lang'))) {
      this.getArticles('fr');
    } else {
      this.getArticles('en');
    }
  },

  async mounted() {
    /* Preload articles (in case the user changes language) */
    if (/^fr/.test(this.$t('lang'))) {
      await this.getArticles('en');
    } else {
      await this.getArticles('fr');
    }
  },

  methods: {
    async getArticles(lg) {
      const url = (lg === 'fr')
        ? 'https://framablog.org/tag/framaccueil/json'
        : 'https://framablog.org/tag/framahome/json';
        try {
        const data = await (await fetch(url)).json();
        const dataFSpace = await (await fetch('https://framablog.org/tag/framaspace/json')).json();

        const items = data.items;

        items.push(...dataFSpace.items);

        items.sort((a, b) => new Date(b.date_published) - new Date(a.date_published));
        const itemsForLanguage = items.filter(item => (lg !== 'fr' && item.tags.includes('English')) || (lg === 'fr' && !item.tags.includes('English') && !item.tags.includes('Traductions')));
   
        itemsForLanguage.forEach((item) => {
          if (this.articles[lg].length < 3) {
            this.articles[lg].push({
              url: item.url,
              title: item.title,
              desc: this.$t(item.content_html.split('<p><span id="more-')[0], '-t'),
              date: item.date_published,
              img: item.image,
            });
          }
        });
        } catch (err) {
          console.error(err)
        }
    },
  },
};
</script>

<style lang="scss">
section#news {
  padding: 5rem 0;

  h2 {
    background: var(--f-o6);
    border-radius: 0.5rem;
    color: #fff;
    font-family: Tovari Sans,sans-serif;
    font-size: 2.4rem !important;
    font-style: normal;
    line-height: 2.5rem !important;
    padding: 1.25rem 1.75rem 0.5rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-2deg);
    width: fit-content;

    @media (min-width: 576px) {
      font-size: 3rem !important;
    }

    margin: -8rem auto 5rem 0 !important;
  }
}

#h-blog {
  margin: 1rem auto;

  > div > div {
    background: var(--f-g2);
    border-radius: 1.75rem;
    padding: .5rem 1.5rem;
    position: relative;
    z-index: 1;
    height: 100%;

    a {
      background-image: none;
      color: var(--f-g9);
      font-size: 1.25rem;
      font-weight: bold;

      &:hover, &:focus {
        text-decoration: none;
      }

      p {
        margin-bottom: .5rem;
      }

      p:last-of-type {
        line-height: 1.2;
      }
    }

    .desc {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }

  h3 {
    background: var(--f-o6);
    border-radius: .5rem;
    color: #fff;
    font-family: 'Tovari Sans', sans-serif;
    font-size: 1.5rem;
    margin: -1.5rem auto 1.5rem 0;
    padding: .25rem .75rem 0rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-2deg);
    width: fit-content;
  }

  .small {
    color: var(--f-g7) !important;
  }

  .btn-outline-warning {
    font-weight: bold;
    border: none;
    background: #fff;

    &:hover, &:focus, &:active {
      background: var(--f-o7);
      color: #fff;
    }
  }
}
</style>
